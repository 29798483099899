/* PrismJS 1.28.0
https://prismjs.com/download.html#themes=prism&languages=markup+css+clike+javascript+http+json+json5+markdown+python+typescript+typoscript+wasm&plugins=line-numbers+show-language+highlight-keywords+toolbar+copy-to-clipboard+download-button */
var _self =
		"undefined" != typeof window
			? window
			: "undefined" != typeof WorkerGlobalScope && self instanceof WorkerGlobalScope
			? self
			: {},
	Prism = (function (e) {
		var n = /(?:^|\s)lang(?:uage)?-([\w-]+)(?=\s|$)/i,
			t = 0,
			r = {},
			a = {
				manual: e.Prism && e.Prism.manual,
				disableWorkerMessageHandler: e.Prism && e.Prism.disableWorkerMessageHandler,
				util: {
					encode: function e(n) {
						return n instanceof i
							? new i(n.type, e(n.content), n.alias)
							: Array.isArray(n)
							? n.map(e)
							: n
									.replace(/&/g, "&amp;")
									.replace(/</g, "&lt;")
									.replace(/\u00a0/g, " ");
					},
					type: function (e) {
						return Object.prototype.toString.call(e).slice(8, -1);
					},
					objId: function (e) {
						return e.__id || Object.defineProperty(e, "__id", { value: ++t }), e.__id;
					},
					clone: function e(n, t) {
						var r, i;
						switch (((t = t || {}), a.util.type(n))) {
							case "Object":
								if (((i = a.util.objId(n)), t[i])) return t[i];
								for (var l in ((r = {}), (t[i] = r), n)) n.hasOwnProperty(l) && (r[l] = e(n[l], t));
								return r;
							case "Array":
								return (
									(i = a.util.objId(n)),
									t[i]
										? t[i]
										: ((r = []),
										  (t[i] = r),
										  n.forEach(function (n, a) {
												r[a] = e(n, t);
										  }),
										  r)
								);
							default:
								return n;
						}
					},
					getLanguage: function (e) {
						for (; e; ) {
							var t = n.exec(e.className);
							if (t) return t[1].toLowerCase();
							e = e.parentElement;
						}
						return "none";
					},
					setLanguage: function (e, t) {
						(e.className = e.className.replace(RegExp(n, "gi"), "")), e.classList.add("language-" + t);
					},
					currentScript: function () {
						if ("undefined" == typeof document) return null;
						if ("currentScript" in document) return document.currentScript;
						try {
							throw new Error();
						} catch (r) {
							var e = (/at [^(\r\n]*\((.*):[^:]+:[^:]+\)$/i.exec(r.stack) || [])[1];
							if (e) {
								var n = document.getElementsByTagName("script");
								for (var t in n) if (n[t].src == e) return n[t];
							}
							return null;
						}
					},
					isActive: function (e, n, t) {
						for (var r = "no-" + n; e; ) {
							var a = e.classList;
							if (a.contains(n)) return !0;
							if (a.contains(r)) return !1;
							e = e.parentElement;
						}
						return !!t;
					},
				},
				languages: {
					plain: r,
					plaintext: r,
					text: r,
					txt: r,
					extend: function (e, n) {
						var t = a.util.clone(a.languages[e]);
						for (var r in n) t[r] = n[r];
						return t;
					},
					insertBefore: function (e, n, t, r) {
						var i = (r = r || a.languages)[e],
							l = {};
						for (var o in i)
							if (i.hasOwnProperty(o)) {
								if (o == n) for (var s in t) t.hasOwnProperty(s) && (l[s] = t[s]);
								t.hasOwnProperty(o) || (l[o] = i[o]);
							}
						var u = r[e];
						return (
							(r[e] = l),
							a.languages.DFS(a.languages, function (n, t) {
								t === u && n != e && (this[n] = l);
							}),
							l
						);
					},
					DFS: function e(n, t, r, i) {
						i = i || {};
						var l = a.util.objId;
						for (var o in n)
							if (n.hasOwnProperty(o)) {
								t.call(n, o, n[o], r || o);
								var s = n[o],
									u = a.util.type(s);
								"Object" !== u || i[l(s)]
									? "Array" !== u || i[l(s)] || ((i[l(s)] = !0), e(s, t, o, i))
									: ((i[l(s)] = !0), e(s, t, null, i));
							}
					},
				},
				plugins: {},
				highlightAll: function (e, n) {
					a.highlightAllUnder(document, e, n);
				},
				highlightAllUnder: function (e, n, t) {
					var r = {
						callback: t,
						container: e,
						selector:
							'code[class*="language-"], [class*="language-"] code, code[class*="lang-"], [class*="lang-"] code',
					};
					a.hooks.run("before-highlightall", r),
						(r.elements = Array.prototype.slice.apply(r.container.querySelectorAll(r.selector))),
						a.hooks.run("before-all-elements-highlight", r);
					for (var i, l = 0; (i = r.elements[l++]); ) a.highlightElement(i, !0 === n, r.callback);
				},
				highlightElement: function (n, t, r) {
					var i = a.util.getLanguage(n),
						l = a.languages[i];
					a.util.setLanguage(n, i);
					var o = n.parentElement;
					o && "pre" === o.nodeName.toLowerCase() && a.util.setLanguage(o, i);
					var s = { element: n, language: i, grammar: l, code: n.textContent };
					function u(e) {
						(s.highlightedCode = e),
							a.hooks.run("before-insert", s),
							(s.element.innerHTML = s.highlightedCode),
							a.hooks.run("after-highlight", s),
							a.hooks.run("complete", s),
							r && r.call(s.element);
					}
					if (
						(a.hooks.run("before-sanity-check", s),
						(o = s.element.parentElement) &&
							"pre" === o.nodeName.toLowerCase() &&
							!o.hasAttribute("tabindex") &&
							o.setAttribute("tabindex", "0"),
						!s.code)
					)
						return a.hooks.run("complete", s), void (r && r.call(s.element));
					if ((a.hooks.run("before-highlight", s), s.grammar))
						if (t && e.Worker) {
							var c = new Worker(a.filename);
							(c.onmessage = function (e) {
								u(e.data);
							}),
								c.postMessage(
									JSON.stringify({
										language: s.language,
										code: s.code,
										immediateClose: !0,
									}),
								);
						} else u(a.highlight(s.code, s.grammar, s.language));
					else u(a.util.encode(s.code));
				},
				highlight: function (e, n, t) {
					var r = { code: e, grammar: n, language: t };
					if ((a.hooks.run("before-tokenize", r), !r.grammar))
						throw new Error('The language "' + r.language + '" has no grammar.');
					return (
						(r.tokens = a.tokenize(r.code, r.grammar)),
						a.hooks.run("after-tokenize", r),
						i.stringify(a.util.encode(r.tokens), r.language)
					);
				},
				tokenize: function (e, n) {
					var t = n.rest;
					if (t) {
						for (var r in t) n[r] = t[r];
						delete n.rest;
					}
					var a = new s();
					return (
						u(a, a.head, e),
						o(e, a, n, a.head, 0),
						(function (e) {
							for (var n = [], t = e.head.next; t !== e.tail; ) n.push(t.value), (t = t.next);
							return n;
						})(a)
					);
				},
				hooks: {
					all: {},
					add: function (e, n) {
						var t = a.hooks.all;
						(t[e] = t[e] || []), t[e].push(n);
					},
					run: function (e, n) {
						var t = a.hooks.all[e];
						if (t && t.length) for (var r, i = 0; (r = t[i++]); ) r(n);
					},
				},
				Token: i,
			};
		function i(e, n, t, r) {
			(this.type = e), (this.content = n), (this.alias = t), (this.length = 0 | (r || "").length);
		}
		function l(e, n, t, r) {
			e.lastIndex = n;
			var a = e.exec(t);
			if (a && r && a[1]) {
				var i = a[1].length;
				(a.index += i), (a[0] = a[0].slice(i));
			}
			return a;
		}
		function o(e, n, t, r, s, g) {
			for (var f in t)
				if (t.hasOwnProperty(f) && t[f]) {
					var h = t[f];
					h = Array.isArray(h) ? h : [h];
					for (var d = 0; d < h.length; ++d) {
						if (g && g.cause == f + "," + d) return;
						var v = h[d],
							p = v.inside,
							m = !!v.lookbehind,
							y = !!v.greedy,
							k = v.alias;
						if (y && !v.pattern.global) {
							var x = v.pattern.toString().match(/[imsuy]*$/)[0];
							v.pattern = RegExp(v.pattern.source, x + "g");
						}
						for (
							var b = v.pattern || v, w = r.next, A = s;
							w !== n.tail && !(g && A >= g.reach);
							A += w.value.length, w = w.next
						) {
							var E = w.value;
							if (n.length > e.length) return;
							if (!(E instanceof i)) {
								var P,
									L = 1;
								if (y) {
									if (!(P = l(b, A, e, m)) || P.index >= e.length) break;
									var S = P.index,
										O = P.index + P[0].length,
										j = A;
									for (j += w.value.length; S >= j; ) j += (w = w.next).value.length;
									if (((A = j -= w.value.length), w.value instanceof i)) continue;
									for (var C = w; C !== n.tail && (j < O || "string" == typeof C.value); C = C.next)
										L++, (j += C.value.length);
									L--, (E = e.slice(A, j)), (P.index -= A);
								} else if (!(P = l(b, 0, E, m))) continue;
								S = P.index;
								var N = P[0],
									_ = E.slice(0, S),
									M = E.slice(S + N.length),
									W = A + E.length;
								g && W > g.reach && (g.reach = W);
								var z = w.prev;
								if (
									(_ && ((z = u(n, z, _)), (A += _.length)),
									c(n, z, L),
									(w = u(n, z, new i(f, p ? a.tokenize(N, p) : N, k, N))),
									M && u(n, w, M),
									L > 1)
								) {
									var I = { cause: f + "," + d, reach: W };
									o(e, n, t, w.prev, A, I), g && I.reach > g.reach && (g.reach = I.reach);
								}
							}
						}
					}
				}
		}
		function s() {
			var e = { value: null, prev: null, next: null },
				n = { value: null, prev: e, next: null };
			(e.next = n), (this.head = e), (this.tail = n), (this.length = 0);
		}
		function u(e, n, t) {
			var r = n.next,
				a = { value: t, prev: n, next: r };
			return (n.next = a), (r.prev = a), e.length++, a;
		}
		function c(e, n, t) {
			for (var r = n.next, a = 0; a < t && r !== e.tail; a++) r = r.next;
			(n.next = r), (r.prev = n), (e.length -= a);
		}
		if (
			((e.Prism = a),
			(i.stringify = function e(n, t) {
				if ("string" == typeof n) return n;
				if (Array.isArray(n)) {
					var r = "";
					return (
						n.forEach(function (n) {
							r += e(n, t);
						}),
						r
					);
				}
				var i = {
						type: n.type,
						content: e(n.content, t),
						tag: "span",
						classes: ["token", n.type],
						attributes: {},
						language: t,
					},
					l = n.alias;
				l && (Array.isArray(l) ? Array.prototype.push.apply(i.classes, l) : i.classes.push(l)), a.hooks.run("wrap", i);
				var o = "";
				for (var s in i.attributes) o += " " + s + '="' + (i.attributes[s] || "").replace(/"/g, "&quot;") + '"';
				return "<" + i.tag + ' class="' + i.classes.join(" ") + '"' + o + ">" + i.content + "</" + i.tag + ">";
			}),
			!e.document)
		)
			return e.addEventListener
				? (a.disableWorkerMessageHandler ||
						e.addEventListener(
							"message",
							function (n) {
								var t = JSON.parse(n.data),
									r = t.language,
									i = t.code,
									l = t.immediateClose;
								e.postMessage(a.highlight(i, a.languages[r], r)), l && e.close();
							},
							!1,
						),
				  a)
				: a;
		var g = a.util.currentScript();
		function f() {
			a.manual || a.highlightAll();
		}
		if ((g && ((a.filename = g.src), g.hasAttribute("data-manual") && (a.manual = !0)), !a.manual)) {
			var h = document.readyState;
			"loading" === h || ("interactive" === h && g && g.defer)
				? document.addEventListener("DOMContentLoaded", f)
				: window.requestAnimationFrame
				? window.requestAnimationFrame(f)
				: window.setTimeout(f, 16);
		}
		return a;
	})(_self);
"undefined" != typeof module && module.exports && (module.exports = Prism),
	"undefined" != typeof global && (global.Prism = Prism);
(Prism.languages.markup = {
	comment: { pattern: /<!--(?:(?!<!--)[\s\S])*?-->/, greedy: !0 },
	prolog: { pattern: /<\?[\s\S]+?\?>/, greedy: !0 },
	doctype: {
		pattern:
			/<!DOCTYPE(?:[^>"'[\]]|"[^"]*"|'[^']*')+(?:\[(?:[^<"'\]]|"[^"]*"|'[^']*'|<(?!!--)|<!--(?:[^-]|-(?!->))*-->)*\]\s*)?>/i,
		greedy: !0,
		inside: {
			"internal-subset": {
				pattern: /(^[^\[]*\[)[\s\S]+(?=\]>$)/,
				lookbehind: !0,
				greedy: !0,
				inside: null,
			},
			string: { pattern: /"[^"]*"|'[^']*'/, greedy: !0 },
			punctuation: /^<!|>$|[[\]]/,
			"doctype-tag": /^DOCTYPE/i,
			name: /[^\s<>'"]+/,
		},
	},
	cdata: { pattern: /<!\[CDATA\[[\s\S]*?\]\]>/i, greedy: !0 },
	tag: {
		pattern:
			/<\/?(?!\d)[^\s>\/=$<%]+(?:\s(?:\s*[^\s>\/=]+(?:\s*=\s*(?:"[^"]*"|'[^']*'|[^\s'">=]+(?=[\s>]))|(?=[\s/>])))+)?\s*\/?>/,
		greedy: !0,
		inside: {
			tag: {
				pattern: /^<\/?[^\s>\/]+/,
				inside: { punctuation: /^<\/?/, namespace: /^[^\s>\/:]+:/ },
			},
			"special-attr": [],
			"attr-value": {
				pattern: /=\s*(?:"[^"]*"|'[^']*'|[^\s'">=]+)/,
				inside: {
					punctuation: [{ pattern: /^=/, alias: "attr-equals" }, /"|'/],
				},
			},
			punctuation: /\/?>/,
			"attr-name": {
				pattern: /[^\s>\/]+/,
				inside: { namespace: /^[^\s>\/:]+:/ },
			},
		},
	},
	entity: [{ pattern: /&[\da-z]{1,8};/i, alias: "named-entity" }, /&#x?[\da-f]{1,8};/i],
}),
	(Prism.languages.markup.tag.inside["attr-value"].inside.entity = Prism.languages.markup.entity),
	(Prism.languages.markup.doctype.inside["internal-subset"].inside = Prism.languages.markup),
	Prism.hooks.add("wrap", function (a) {
		"entity" === a.type && (a.attributes.title = a.content.replace(/&amp;/, "&"));
	}),
	Object.defineProperty(Prism.languages.markup.tag, "addInlined", {
		value: function (a, e) {
			var s = {};
			(s["language-" + e] = {
				pattern: /(^<!\[CDATA\[)[\s\S]+?(?=\]\]>$)/i,
				lookbehind: !0,
				inside: Prism.languages[e],
			}),
				(s.cdata = /^<!\[CDATA\[|\]\]>$/i);
			var t = {
				"included-cdata": { pattern: /<!\[CDATA\[[\s\S]*?\]\]>/i, inside: s },
			};
			t["language-" + e] = { pattern: /[\s\S]+/, inside: Prism.languages[e] };
			var n = {};
			(n[a] = {
				pattern: RegExp(
					"(<__[^>]*>)(?:<!\\[CDATA\\[(?:[^\\]]|\\](?!\\]>))*\\]\\]>|(?!<!\\[CDATA\\[)[^])*?(?=</__>)".replace(
						/__/g,
						function () {
							return a;
						},
					),
					"i",
				),
				lookbehind: !0,
				greedy: !0,
				inside: t,
			}),
				Prism.languages.insertBefore("markup", "cdata", n);
		},
	}),
	Object.defineProperty(Prism.languages.markup.tag, "addAttribute", {
		value: function (a, e) {
			Prism.languages.markup.tag.inside["special-attr"].push({
				pattern: RegExp("(^|[\"'\\s])(?:" + a + ")\\s*=\\s*(?:\"[^\"]*\"|'[^']*'|[^\\s'\">=]+(?=[\\s>]))", "i"),
				lookbehind: !0,
				inside: {
					"attr-name": /^[^\s=]+/,
					"attr-value": {
						pattern: /=[\s\S]+/,
						inside: {
							value: {
								pattern: /(^=\s*(["']|(?!["'])))\S[\s\S]*(?=\2$)/,
								lookbehind: !0,
								alias: [e, "language-" + e],
								inside: Prism.languages[e],
							},
							punctuation: [{ pattern: /^=/, alias: "attr-equals" }, /"|'/],
						},
					},
				},
			});
		},
	}),
	(Prism.languages.html = Prism.languages.markup),
	(Prism.languages.mathml = Prism.languages.markup),
	(Prism.languages.svg = Prism.languages.markup),
	(Prism.languages.xml = Prism.languages.extend("markup", {})),
	(Prism.languages.ssml = Prism.languages.xml),
	(Prism.languages.atom = Prism.languages.xml),
	(Prism.languages.rss = Prism.languages.xml);
!(function (s) {
	var e = /(?:"(?:\\(?:\r\n|[\s\S])|[^"\\\r\n])*"|'(?:\\(?:\r\n|[\s\S])|[^'\\\r\n])*')/;
	(s.languages.css = {
		comment: /\/\*[\s\S]*?\*\//,
		atrule: {
			pattern: RegExp("@[\\w-](?:[^;{\\s\"']|\\s+(?!\\s)|" + e.source + ")*?(?:;|(?=\\s*\\{))"),
			inside: {
				rule: /^@[\w-]+/,
				"selector-function-argument": {
					pattern: /(\bselector\s*\(\s*(?![\s)]))(?:[^()\s]|\s+(?![\s)])|\((?:[^()]|\([^()]*\))*\))+(?=\s*\))/,
					lookbehind: !0,
					alias: "selector",
				},
				keyword: {
					pattern: /(^|[^\w-])(?:and|not|only|or)(?![\w-])/,
					lookbehind: !0,
				},
			},
		},
		url: {
			pattern: RegExp("\\burl\\((?:" + e.source + "|(?:[^\\\\\r\n()\"']|\\\\[^])*)\\)", "i"),
			greedy: !0,
			inside: {
				function: /^url/i,
				punctuation: /^\(|\)$/,
				string: { pattern: RegExp("^" + e.source + "$"), alias: "url" },
			},
		},
		selector: {
			pattern: RegExp("(^|[{}\\s])[^{}\\s](?:[^{};\"'\\s]|\\s+(?![\\s{])|" + e.source + ")*(?=\\s*\\{)"),
			lookbehind: !0,
		},
		string: { pattern: e, greedy: !0 },
		property: {
			pattern: /(^|[^-\w\xA0-\uFFFF])(?!\s)[-_a-z\xA0-\uFFFF](?:(?!\s)[-\w\xA0-\uFFFF])*(?=\s*:)/i,
			lookbehind: !0,
		},
		important: /!important\b/i,
		function: { pattern: /(^|[^-a-z0-9])[-a-z0-9]+(?=\()/i, lookbehind: !0 },
		punctuation: /[(){};:,]/,
	}),
		(s.languages.css.atrule.inside.rest = s.languages.css);
	var t = s.languages.markup;
	t && (t.tag.addInlined("style", "css"), t.tag.addAttribute("style", "css"));
})(Prism);
Prism.languages.clike = {
	comment: [
		{ pattern: /(^|[^\\])\/\*[\s\S]*?(?:\*\/|$)/, lookbehind: !0, greedy: !0 },
		{ pattern: /(^|[^\\:])\/\/.*/, lookbehind: !0, greedy: !0 },
	],
	string: {
		pattern: /(["'])(?:\\(?:\r\n|[\s\S])|(?!\1)[^\\\r\n])*\1/,
		greedy: !0,
	},
	"class-name": {
		pattern: /(\b(?:class|extends|implements|instanceof|interface|new|trait)\s+|\bcatch\s+\()[\w.\\]+/i,
		lookbehind: !0,
		inside: { punctuation: /[.\\]/ },
	},
	keyword: /\b(?:break|catch|continue|do|else|finally|for|function|if|in|instanceof|new|null|return|throw|try|while)\b/,
	boolean: /\b(?:false|true)\b/,
	function: /\b\w+(?=\()/,
	number: /\b0x[\da-f]+\b|(?:\b\d+(?:\.\d*)?|\B\.\d+)(?:e[+-]?\d+)?/i,
	operator: /[<>]=?|[!=]=?=?|--?|\+\+?|&&?|\|\|?|[?*/~^%]/,
	punctuation: /[{}[\];(),.:]/,
};
(Prism.languages.javascript = Prism.languages.extend("clike", {
	"class-name": [
		Prism.languages.clike["class-name"],
		{
			pattern:
				/(^|[^$\w\xA0-\uFFFF])(?!\s)[_$A-Z\xA0-\uFFFF](?:(?!\s)[$\w\xA0-\uFFFF])*(?=\.(?:constructor|prototype))/,
			lookbehind: !0,
		},
	],
	keyword: [
		{ pattern: /((?:^|\})\s*)catch\b/, lookbehind: !0 },
		{
			pattern:
				/(^|[^.]|\.\.\.\s*)\b(?:as|assert(?=\s*\{)|async(?=\s*(?:function\b|\(|[$\w\xA0-\uFFFF]|$))|await|break|case|class|const|continue|debugger|default|delete|do|else|enum|export|extends|finally(?=\s*(?:\{|$))|for|from(?=\s*(?:['"]|$))|function|(?:get|set)(?=\s*(?:[#\[$\w\xA0-\uFFFF]|$))|if|implements|import|in|instanceof|interface|let|new|null|of|package|private|protected|public|return|static|super|switch|this|throw|try|typeof|undefined|var|void|while|with|yield)\b/,
			lookbehind: !0,
		},
	],
	function: /#?(?!\s)[_$a-zA-Z\xA0-\uFFFF](?:(?!\s)[$\w\xA0-\uFFFF])*(?=\s*(?:\.\s*(?:apply|bind|call)\s*)?\()/,
	number: {
		pattern: RegExp(
			"(^|[^\\w$])(?:NaN|Infinity|0[bB][01]+(?:_[01]+)*n?|0[oO][0-7]+(?:_[0-7]+)*n?|0[xX][\\dA-Fa-f]+(?:_[\\dA-Fa-f]+)*n?|\\d+(?:_\\d+)*n|(?:\\d+(?:_\\d+)*(?:\\.(?:\\d+(?:_\\d+)*)?)?|\\.\\d+(?:_\\d+)*)(?:[Ee][+-]?\\d+(?:_\\d+)*)?)(?![\\w$])",
		),
		lookbehind: !0,
	},
	operator: /--|\+\+|\*\*=?|=>|&&=?|\|\|=?|[!=]==|<<=?|>>>?=?|[-+*/%&|^!=<>]=?|\.{3}|\?\?=?|\?\.?|[~:]/,
})),
	(Prism.languages.javascript["class-name"][0].pattern =
		/(\b(?:class|extends|implements|instanceof|interface|new)\s+)[\w.\\]+/),
	Prism.languages.insertBefore("javascript", "keyword", {
		regex: {
			pattern: RegExp(
				"((?:^|[^$\\w\\xA0-\\uFFFF.\"'\\])\\s]|\\b(?:return|yield))\\s*)/(?:(?:\\[(?:[^\\]\\\\\r\n]|\\\\.)*\\]|\\\\.|[^/\\\\\\[\r\n])+/[dgimyus]{0,7}|(?:\\[(?:[^[\\]\\\\\r\n]|\\\\.|\\[(?:[^[\\]\\\\\r\n]|\\\\.|\\[(?:[^[\\]\\\\\r\n]|\\\\.)*\\])*\\])*\\]|\\\\.|[^/\\\\\\[\r\n])+/[dgimyus]{0,7}v[dgimyus]{0,7})(?=(?:\\s|/\\*(?:[^*]|\\*(?!/))*\\*/)*(?:$|[\r\n,.;:})\\]]|//))",
			),
			lookbehind: !0,
			greedy: !0,
			inside: {
				"regex-source": {
					pattern: /^(\/)[\s\S]+(?=\/[a-z]*$)/,
					lookbehind: !0,
					alias: "language-regex",
					inside: Prism.languages.regex,
				},
				"regex-delimiter": /^\/|\/$/,
				"regex-flags": /^[a-z]+$/,
			},
		},
		"function-variable": {
			pattern:
				/#?(?!\s)[_$a-zA-Z\xA0-\uFFFF](?:(?!\s)[$\w\xA0-\uFFFF])*(?=\s*[=:]\s*(?:async\s*)?(?:\bfunction\b|(?:\((?:[^()]|\([^()]*\))*\)|(?!\s)[_$a-zA-Z\xA0-\uFFFF](?:(?!\s)[$\w\xA0-\uFFFF])*)\s*=>))/,
			alias: "function",
		},
		parameter: [
			{
				pattern:
					/(function(?:\s+(?!\s)[_$a-zA-Z\xA0-\uFFFF](?:(?!\s)[$\w\xA0-\uFFFF])*)?\s*\(\s*)(?!\s)(?:[^()\s]|\s+(?![\s)])|\([^()]*\))+(?=\s*\))/,
				lookbehind: !0,
				inside: Prism.languages.javascript,
			},
			{
				pattern: /(^|[^$\w\xA0-\uFFFF])(?!\s)[_$a-z\xA0-\uFFFF](?:(?!\s)[$\w\xA0-\uFFFF])*(?=\s*=>)/i,
				lookbehind: !0,
				inside: Prism.languages.javascript,
			},
			{
				pattern: /(\(\s*)(?!\s)(?:[^()\s]|\s+(?![\s)])|\([^()]*\))+(?=\s*\)\s*=>)/,
				lookbehind: !0,
				inside: Prism.languages.javascript,
			},
			{
				pattern:
					/((?:\b|\s|^)(?!(?:as|async|await|break|case|catch|class|const|continue|debugger|default|delete|do|else|enum|export|extends|finally|for|from|function|get|if|implements|import|in|instanceof|interface|let|new|null|of|package|private|protected|public|return|set|static|super|switch|this|throw|try|typeof|undefined|var|void|while|with|yield)(?![$\w\xA0-\uFFFF]))(?:(?!\s)[_$a-zA-Z\xA0-\uFFFF](?:(?!\s)[$\w\xA0-\uFFFF])*\s*)\(\s*|\]\s*\(\s*)(?!\s)(?:[^()\s]|\s+(?![\s)])|\([^()]*\))+(?=\s*\)\s*\{)/,
				lookbehind: !0,
				inside: Prism.languages.javascript,
			},
		],
		constant: /\b[A-Z](?:[A-Z_]|\dx?)*\b/,
	}),
	Prism.languages.insertBefore("javascript", "string", {
		hashbang: { pattern: /^#!.*/, greedy: !0, alias: "comment" },
		"template-string": {
			pattern: /`(?:\\[\s\S]|\$\{(?:[^{}]|\{(?:[^{}]|\{[^}]*\})*\})+\}|(?!\$\{)[^\\`])*`/,
			greedy: !0,
			inside: {
				"template-punctuation": { pattern: /^`|`$/, alias: "string" },
				interpolation: {
					pattern: /((?:^|[^\\])(?:\\{2})*)\$\{(?:[^{}]|\{(?:[^{}]|\{[^}]*\})*\})+\}/,
					lookbehind: !0,
					inside: {
						"interpolation-punctuation": {
							pattern: /^\$\{|\}$/,
							alias: "punctuation",
						},
						rest: Prism.languages.javascript,
					},
				},
				string: /[\s\S]+/,
			},
		},
		"string-property": {
			pattern: /((?:^|[,{])[ \t]*)(["'])(?:\\(?:\r\n|[\s\S])|(?!\2)[^\\\r\n])*\2(?=\s*:)/m,
			lookbehind: !0,
			greedy: !0,
			alias: "property",
		},
	}),
	Prism.languages.insertBefore("javascript", "operator", {
		"literal-property": {
			pattern: /((?:^|[,{])[ \t]*)(?!\s)[_$a-zA-Z\xA0-\uFFFF](?:(?!\s)[$\w\xA0-\uFFFF])*(?=\s*:)/m,
			lookbehind: !0,
			alias: "property",
		},
	}),
	Prism.languages.markup &&
		(Prism.languages.markup.tag.addInlined("script", "javascript"),
		Prism.languages.markup.tag.addAttribute(
			"on(?:abort|blur|change|click|composition(?:end|start|update)|dblclick|error|focus(?:in|out)?|key(?:down|up)|load|mouse(?:down|enter|leave|move|out|over|up)|reset|resize|scroll|select|slotchange|submit|unload|wheel)",
			"javascript",
		)),
	(Prism.languages.js = Prism.languages.javascript);
!(function (t) {
	function a(t) {
		return RegExp("(^(?:" + t + "):[ \t]*(?![ \t]))[^]+", "i");
	}
	t.languages.http = {
		"request-line": {
			pattern:
				/^(?:CONNECT|DELETE|GET|HEAD|OPTIONS|PATCH|POST|PRI|PUT|SEARCH|TRACE)\s(?:https?:\/\/|\/)\S*\sHTTP\/[\d.]+/m,
			inside: {
				method: { pattern: /^[A-Z]+\b/, alias: "property" },
				"request-target": {
					pattern: /^(\s)(?:https?:\/\/|\/)\S*(?=\s)/,
					lookbehind: !0,
					alias: "url",
					inside: t.languages.uri,
				},
				"http-version": {
					pattern: /^(\s)HTTP\/[\d.]+/,
					lookbehind: !0,
					alias: "property",
				},
			},
		},
		"response-status": {
			pattern: /^HTTP\/[\d.]+ \d+ .+/m,
			inside: {
				"http-version": { pattern: /^HTTP\/[\d.]+/, alias: "property" },
				"status-code": {
					pattern: /^(\s)\d+(?=\s)/,
					lookbehind: !0,
					alias: "number",
				},
				"reason-phrase": {
					pattern: /^(\s).+/,
					lookbehind: !0,
					alias: "string",
				},
			},
		},
		header: {
			pattern: /^[\w-]+:.+(?:(?:\r\n?|\n)[ \t].+)*/m,
			inside: {
				"header-value": [
					{
						pattern: a("Content-Security-Policy"),
						lookbehind: !0,
						alias: ["csp", "languages-csp"],
						inside: t.languages.csp,
					},
					{
						pattern: a("Public-Key-Pins(?:-Report-Only)?"),
						lookbehind: !0,
						alias: ["hpkp", "languages-hpkp"],
						inside: t.languages.hpkp,
					},
					{
						pattern: a("Strict-Transport-Security"),
						lookbehind: !0,
						alias: ["hsts", "languages-hsts"],
						inside: t.languages.hsts,
					},
					{ pattern: a("[^:]+"), lookbehind: !0 },
				],
				"header-name": { pattern: /^[^:]+/, alias: "keyword" },
				punctuation: /^:/,
			},
		},
	};
	var e,
		n = t.languages,
		s = {
			"application/javascript": n.javascript,
			"application/json": n.json || n.javascript,
			"application/xml": n.xml,
			"text/xml": n.xml,
			"text/html": n.html,
			"text/css": n.css,
			"text/plain": n.plain,
		},
		i = { "application/json": !0, "application/xml": !0 };
	function r(t) {
		var a = t.replace(/^[a-z]+\//, "");
		return "(?:" + t + "|\\w+/(?:[\\w.-]+\\+)+" + a + "(?![+\\w.-]))";
	}
	for (var p in s)
		if (s[p]) {
			e = e || {};
			var l = i[p] ? r(p) : p;
			e[p.replace(/\//g, "-")] = {
				pattern: RegExp(
					"(content-type:\\s*" + l + "(?:(?:\r\n?|\n)[\\w-].*)*(?:\r(?:\n|(?!\n))|\n))[^ \t\\w-][^]*",
					"i",
				),
				lookbehind: !0,
				inside: s[p],
			};
		}
	e && t.languages.insertBefore("http", "header", e);
})(Prism);
(Prism.languages.json = {
	property: {
		pattern: /(^|[^\\])"(?:\\.|[^\\"\r\n])*"(?=\s*:)/,
		lookbehind: !0,
		greedy: !0,
	},
	string: {
		pattern: /(^|[^\\])"(?:\\.|[^\\"\r\n])*"(?!\s*:)/,
		lookbehind: !0,
		greedy: !0,
	},
	comment: { pattern: /\/\/.*|\/\*[\s\S]*?(?:\*\/|$)/, greedy: !0 },
	number: /-?\b\d+(?:\.\d+)?(?:e[+-]?\d+)?\b/i,
	punctuation: /[{}[\],]/,
	operator: /:/,
	boolean: /\b(?:false|true)\b/,
	null: { pattern: /\bnull\b/, alias: "keyword" },
}),
	(Prism.languages.webmanifest = Prism.languages.json);
!(function (n) {
	var e = /("|')(?:\\(?:\r\n?|\n|.)|(?!\1)[^\\\r\n])*\1/;
	n.languages.json5 = n.languages.extend("json", {
		property: [
			{ pattern: RegExp(e.source + "(?=\\s*:)"), greedy: !0 },
			{
				pattern: /(?!\s)[_$a-zA-Z\xA0-\uFFFF](?:(?!\s)[$\w\xA0-\uFFFF])*(?=\s*:)/,
				alias: "unquoted",
			},
		],
		string: { pattern: e, greedy: !0 },
		number: /[+-]?\b(?:NaN|Infinity|0x[a-fA-F\d]+)\b|[+-]?(?:\b\d+(?:\.\d*)?|\B\.\d+)(?:[eE][+-]?\d+\b)?/,
	});
})(Prism);
!(function (n) {
	function e(n) {
		return (
			(n = n.replace(/<inner>/g, function () {
				return "(?:\\\\.|[^\\\\\n\r]|(?:\n|\r\n?)(?![\r\n]))";
			})),
			RegExp("((?:^|[^\\\\])(?:\\\\{2})*)(?:" + n + ")")
		);
	}
	var t = "(?:\\\\.|``(?:[^`\r\n]|`(?!`))+``|`[^`\r\n]+`|[^\\\\|\r\n`])+",
		a = "\\|?__(?:\\|__)+\\|?(?:(?:\n|\r\n?)|(?![^]))".replace(/__/g, function () {
			return t;
		}),
		i = "\\|?[ \t]*:?-{3,}:?[ \t]*(?:\\|[ \t]*:?-{3,}:?[ \t]*)+\\|?(?:\n|\r\n?)";
	(n.languages.markdown = n.languages.extend("markup", {})),
		n.languages.insertBefore("markdown", "prolog", {
			"front-matter-block": {
				pattern: /(^(?:\s*[\r\n])?)---(?!.)[\s\S]*?[\r\n]---(?!.)/,
				lookbehind: !0,
				greedy: !0,
				inside: {
					punctuation: /^---|---$/,
					"front-matter": {
						pattern: /\S+(?:\s+\S+)*/,
						alias: ["yaml", "language-yaml"],
						inside: n.languages.yaml,
					},
				},
			},
			blockquote: { pattern: /^>(?:[\t ]*>)*/m, alias: "punctuation" },
			table: {
				pattern: RegExp("^" + a + i + "(?:" + a + ")*", "m"),
				inside: {
					"table-data-rows": {
						pattern: RegExp("^(" + a + i + ")(?:" + a + ")*$"),
						lookbehind: !0,
						inside: {
							"table-data": {
								pattern: RegExp(t),
								inside: n.languages.markdown,
							},
							punctuation: /\|/,
						},
					},
					"table-line": {
						pattern: RegExp("^(" + a + ")" + i + "$"),
						lookbehind: !0,
						inside: { punctuation: /\||:?-{3,}:?/ },
					},
					"table-header-row": {
						pattern: RegExp("^" + a + "$"),
						inside: {
							"table-header": {
								pattern: RegExp(t),
								alias: "important",
								inside: n.languages.markdown,
							},
							punctuation: /\|/,
						},
					},
				},
			},
			code: [
				{
					pattern: /((?:^|\n)[ \t]*\n|(?:^|\r\n?)[ \t]*\r\n?)(?: {4}|\t).+(?:(?:\n|\r\n?)(?: {4}|\t).+)*/,
					lookbehind: !0,
					alias: "keyword",
				},
				{
					pattern: /^```[\s\S]*?^```$/m,
					greedy: !0,
					inside: {
						"code-block": {
							pattern: /^(```.*(?:\n|\r\n?))[\s\S]+?(?=(?:\n|\r\n?)^```$)/m,
							lookbehind: !0,
						},
						"code-language": { pattern: /^(```).+/, lookbehind: !0 },
						punctuation: /```/,
					},
				},
			],
			title: [
				{
					pattern: /\S.*(?:\n|\r\n?)(?:==+|--+)(?=[ \t]*$)/m,
					alias: "important",
					inside: { punctuation: /==+$|--+$/ },
				},
				{
					pattern: /(^\s*)#.+/m,
					lookbehind: !0,
					alias: "important",
					inside: { punctuation: /^#+|#+$/ },
				},
			],
			hr: {
				pattern: /(^\s*)([*-])(?:[\t ]*\2){2,}(?=\s*$)/m,
				lookbehind: !0,
				alias: "punctuation",
			},
			list: {
				pattern: /(^\s*)(?:[*+-]|\d+\.)(?=[\t ].)/m,
				lookbehind: !0,
				alias: "punctuation",
			},
			"url-reference": {
				pattern:
					/!?\[[^\]]+\]:[\t ]+(?:\S+|<(?:\\.|[^>\\])+>)(?:[\t ]+(?:"(?:\\.|[^"\\])*"|'(?:\\.|[^'\\])*'|\((?:\\.|[^)\\])*\)))?/,
				inside: {
					variable: { pattern: /^(!?\[)[^\]]+/, lookbehind: !0 },
					string: /(?:"(?:\\.|[^"\\])*"|'(?:\\.|[^'\\])*'|\((?:\\.|[^)\\])*\))$/,
					punctuation: /^[\[\]!:]|[<>]/,
				},
				alias: "url",
			},
			bold: {
				pattern: e(
					"\\b__(?:(?!_)<inner>|_(?:(?!_)<inner>)+_)+__\\b|\\*\\*(?:(?!\\*)<inner>|\\*(?:(?!\\*)<inner>)+\\*)+\\*\\*",
				),
				lookbehind: !0,
				greedy: !0,
				inside: {
					content: {
						pattern: /(^..)[\s\S]+(?=..$)/,
						lookbehind: !0,
						inside: {},
					},
					punctuation: /\*\*|__/,
				},
			},
			italic: {
				pattern: e(
					"\\b_(?:(?!_)<inner>|__(?:(?!_)<inner>)+__)+_\\b|\\*(?:(?!\\*)<inner>|\\*\\*(?:(?!\\*)<inner>)+\\*\\*)+\\*",
				),
				lookbehind: !0,
				greedy: !0,
				inside: {
					content: { pattern: /(^.)[\s\S]+(?=.$)/, lookbehind: !0, inside: {} },
					punctuation: /[*_]/,
				},
			},
			strike: {
				pattern: e("(~~?)(?:(?!~)<inner>)+\\2"),
				lookbehind: !0,
				greedy: !0,
				inside: {
					content: {
						pattern: /(^~~?)[\s\S]+(?=\1$)/,
						lookbehind: !0,
						inside: {},
					},
					punctuation: /~~?/,
				},
			},
			"code-snippet": {
				pattern: /(^|[^\\`])(?:``[^`\r\n]+(?:`[^`\r\n]+)*``(?!`)|`[^`\r\n]+`(?!`))/,
				lookbehind: !0,
				greedy: !0,
				alias: ["code", "keyword"],
			},
			url: {
				pattern: e(
					'!?\\[(?:(?!\\])<inner>)+\\](?:\\([^\\s)]+(?:[\t ]+"(?:\\\\.|[^"\\\\])*")?\\)|[ \t]?\\[(?:(?!\\])<inner>)+\\])',
				),
				lookbehind: !0,
				greedy: !0,
				inside: {
					operator: /^!/,
					content: { pattern: /(^\[)[^\]]+(?=\])/, lookbehind: !0, inside: {} },
					variable: { pattern: /(^\][ \t]?\[)[^\]]+(?=\]$)/, lookbehind: !0 },
					url: { pattern: /(^\]\()[^\s)]+/, lookbehind: !0 },
					string: {
						pattern: /(^[ \t]+)"(?:\\.|[^"\\])*"(?=\)$)/,
						lookbehind: !0,
					},
				},
			},
		}),
		["url", "bold", "italic", "strike"].forEach(function (e) {
			["url", "bold", "italic", "strike", "code-snippet"].forEach(function (t) {
				e !== t && (n.languages.markdown[e].inside.content.inside[t] = n.languages.markdown[t]);
			});
		}),
		n.hooks.add("after-tokenize", function (n) {
			("markdown" !== n.language && "md" !== n.language) ||
				(function n(e) {
					if (e && "string" != typeof e)
						for (var t = 0, a = e.length; t < a; t++) {
							var i = e[t];
							if ("code" === i.type) {
								var r = i.content[1],
									o = i.content[3];
								if (r && o && "code-language" === r.type && "code-block" === o.type && "string" == typeof r.content) {
									var l = r.content.replace(/\b#/g, "sharp").replace(/\b\+\+/g, "pp"),
										s = "language-" + (l = (/[a-z][\w-]*/i.exec(l) || [""])[0].toLowerCase());
									o.alias ? ("string" == typeof o.alias ? (o.alias = [o.alias, s]) : o.alias.push(s)) : (o.alias = [s]);
								}
							} else n(i.content);
						}
				})(n.tokens);
		}),
		n.hooks.add("wrap", function (e) {
			if ("code-block" === e.type) {
				for (var t = "", a = 0, i = e.classes.length; a < i; a++) {
					var s = e.classes[a],
						d = /language-(.+)/.exec(s);
					if (d) {
						t = d[1];
						break;
					}
				}
				var p = n.languages[t];
				if (p)
					e.content = n.highlight(
						e.content.replace(r, "").replace(/&(\w{1,8}|#x?[\da-f]{1,8});/gi, function (n, e) {
							var t;
							return "#" === (e = e.toLowerCase())[0]
								? ((t = "x" === e[1] ? parseInt(e.slice(2), 16) : Number(e.slice(1))), l(t))
								: o[e] || n;
						}),
						p,
						t,
					);
				else if (t && "none" !== t && n.plugins.autoloader) {
					var u = "md-" + new Date().valueOf() + "-" + Math.floor(1e16 * Math.random());
					(e.attributes.id = u),
						n.plugins.autoloader.loadLanguages(t, function () {
							var e = document.getElementById(u);
							e && (e.innerHTML = n.highlight(e.textContent, n.languages[t], t));
						});
				}
			}
		});
	var r = RegExp(n.languages.markup.tag.pattern.source, "gi"),
		o = { amp: "&", lt: "<", gt: ">", quot: '"' },
		l = String.fromCodePoint || String.fromCharCode;
	n.languages.md = n.languages.markdown;
})(Prism);
(Prism.languages.python = {
	comment: { pattern: /(^|[^\\])#.*/, lookbehind: !0, greedy: !0 },
	"string-interpolation": {
		pattern: /(?:f|fr|rf)(?:("""|''')[\s\S]*?\1|("|')(?:\\.|(?!\2)[^\\\r\n])*\2)/i,
		greedy: !0,
		inside: {
			interpolation: {
				pattern: /((?:^|[^{])(?:\{\{)*)\{(?!\{)(?:[^{}]|\{(?!\{)(?:[^{}]|\{(?!\{)(?:[^{}])+\})+\})+\}/,
				lookbehind: !0,
				inside: {
					"format-spec": { pattern: /(:)[^:(){}]+(?=\}$)/, lookbehind: !0 },
					"conversion-option": {
						pattern: /![sra](?=[:}]$)/,
						alias: "punctuation",
					},
					rest: null,
				},
			},
			string: /[\s\S]+/,
		},
	},
	"triple-quoted-string": {
		pattern: /(?:[rub]|br|rb)?("""|''')[\s\S]*?\1/i,
		greedy: !0,
		alias: "string",
	},
	string: {
		pattern: /(?:[rub]|br|rb)?("|')(?:\\.|(?!\1)[^\\\r\n])*\1/i,
		greedy: !0,
	},
	function: {
		pattern: /((?:^|\s)def[ \t]+)[a-zA-Z_]\w*(?=\s*\()/g,
		lookbehind: !0,
	},
	"class-name": { pattern: /(\bclass\s+)\w+/i, lookbehind: !0 },
	decorator: {
		pattern: /(^[\t ]*)@\w+(?:\.\w+)*/m,
		lookbehind: !0,
		alias: ["annotation", "punctuation"],
		inside: { punctuation: /\./ },
	},
	keyword:
		/\b(?:_(?=\s*:)|and|as|assert|async|await|break|case|class|continue|def|del|elif|else|except|exec|finally|for|from|global|if|import|in|is|lambda|match|nonlocal|not|or|pass|print|raise|return|try|while|with|yield)\b/,
	builtin:
		/\b(?:__import__|abs|all|any|apply|ascii|basestring|bin|bool|buffer|bytearray|bytes|callable|chr|classmethod|cmp|coerce|compile|complex|delattr|dict|dir|divmod|enumerate|eval|execfile|file|filter|float|format|frozenset|getattr|globals|hasattr|hash|help|hex|id|input|int|intern|isinstance|issubclass|iter|len|list|locals|long|map|max|memoryview|min|next|object|oct|open|ord|pow|property|range|raw_input|reduce|reload|repr|reversed|round|set|setattr|slice|sorted|staticmethod|str|sum|super|tuple|type|unichr|unicode|vars|xrange|zip)\b/,
	boolean: /\b(?:False|None|True)\b/,
	number:
		/\b0(?:b(?:_?[01])+|o(?:_?[0-7])+|x(?:_?[a-f0-9])+)\b|(?:\b\d+(?:_\d+)*(?:\.(?:\d+(?:_\d+)*)?)?|\B\.\d+(?:_\d+)*)(?:e[+-]?\d+(?:_\d+)*)?j?(?!\w)/i,
	operator: /[-+%=]=?|!=|:=|\*\*?=?|\/\/?=?|<[<=>]?|>[=>]?|[&|^~]/,
	punctuation: /[{}[\];(),.:]/,
}),
	(Prism.languages.python["string-interpolation"].inside.interpolation.inside.rest = Prism.languages.python),
	(Prism.languages.py = Prism.languages.python);
!(function (e) {
	(e.languages.typescript = e.languages.extend("javascript", {
		"class-name": {
			pattern:
				/(\b(?:class|extends|implements|instanceof|interface|new|type)\s+)(?!keyof\b)(?!\s)[_$a-zA-Z\xA0-\uFFFF](?:(?!\s)[$\w\xA0-\uFFFF])*(?:\s*<(?:[^<>]|<(?:[^<>]|<[^<>]*>)*>)*>)?/,
			lookbehind: !0,
			greedy: !0,
			inside: null,
		},
		builtin: /\b(?:Array|Function|Promise|any|boolean|console|never|number|string|symbol|unknown)\b/,
	})),
		e.languages.typescript.keyword.push(
			/\b(?:abstract|declare|is|keyof|readonly|require)\b/,
			/\b(?:asserts|infer|interface|module|namespace|type)\b(?=\s*(?:[{_$a-zA-Z\xA0-\uFFFF]|$))/,
			/\btype\b(?=\s*(?:[\{*]|$))/,
		),
		delete e.languages.typescript.parameter,
		delete e.languages.typescript["literal-property"];
	var s = e.languages.extend("typescript", {});
	delete s["class-name"],
		(e.languages.typescript["class-name"].inside = s),
		e.languages.insertBefore("typescript", "function", {
			decorator: {
				pattern: /@[$\w\xA0-\uFFFF]+/,
				inside: {
					at: { pattern: /^@/, alias: "operator" },
					function: /^[\s\S]+/,
				},
			},
			"generic-function": {
				pattern:
					/#?(?!\s)[_$a-zA-Z\xA0-\uFFFF](?:(?!\s)[$\w\xA0-\uFFFF])*\s*<(?:[^<>]|<(?:[^<>]|<[^<>]*>)*>)*>(?=\s*\()/,
				greedy: !0,
				inside: {
					function: /^#?(?!\s)[_$a-zA-Z\xA0-\uFFFF](?:(?!\s)[$\w\xA0-\uFFFF])*/,
					generic: { pattern: /<[\s\S]+/, alias: "class-name", inside: s },
				},
			},
		}),
		(e.languages.ts = e.languages.typescript);
})(Prism);
!(function (E) {
	var n =
		/\b(?:ACT|ACTIFSUB|CARRAY|CASE|CLEARGIF|COA|COA_INT|CONSTANTS|CONTENT|CUR|EDITPANEL|EFFECT|EXT|FILE|FLUIDTEMPLATE|FORM|FRAME|FRAMESET|GIFBUILDER|GMENU|GMENU_FOLDOUT|GMENU_LAYERS|GP|HMENU|HRULER|HTML|IENV|IFSUB|IMAGE|IMGMENU|IMGMENUITEM|IMGTEXT|IMG_RESOURCE|INCLUDE_TYPOSCRIPT|JSMENU|JSMENUITEM|LLL|LOAD_REGISTER|NO|PAGE|RECORDS|RESTORE_REGISTER|TEMPLATE|TEXT|TMENU|TMENUITEM|TMENU_LAYERS|USER|USER_INT|_GIFBUILDER|global|globalString|globalVar)\b/;
	(E.languages.typoscript = {
		comment: [
			{ pattern: /(^|[^\\])\/\*[\s\S]*?(?:\*\/|$)/, lookbehind: !0 },
			{
				pattern: /(^|[^\\:= \t]|(?:^|[^= \t])[ \t]+)\/\/.*/,
				lookbehind: !0,
				greedy: !0,
			},
			{ pattern: /(^|[^"'])#.*/, lookbehind: !0, greedy: !0 },
		],
		function: [
			{
				pattern: /<INCLUDE_TYPOSCRIPT:\s*source\s*=\s*(?:"[^"\r\n]*"|'[^'\r\n]*')\s*>/,
				inside: {
					string: {
						pattern: /"[^"\r\n]*"|'[^'\r\n]*'/,
						inside: { keyword: n },
					},
					keyword: { pattern: /INCLUDE_TYPOSCRIPT/ },
				},
			},
			{
				pattern: /@import\s*(?:"[^"\r\n]*"|'[^'\r\n]*')/,
				inside: { string: /"[^"\r\n]*"|'[^'\r\n]*'/ },
			},
		],
		string: {
			pattern: /^([^=]*=[< ]?)(?:(?!\]\n).)*/,
			lookbehind: !0,
			inside: {
				function: /\{\$.*\}/,
				keyword: n,
				number: /^\d+$/,
				punctuation: /[,|:]/,
			},
		},
		keyword: n,
		number: { pattern: /\b\d+\s*[.{=]/, inside: { operator: /[.{=]/ } },
		tag: { pattern: /\.?[-\w\\]+\.?/, inside: { punctuation: /\./ } },
		punctuation: /[{}[\];(),.:|]/,
		operator: /[<>]=?|[!=]=?=?|--?|\+\+?|&&?|\|\|?|[?*/~^%]/,
	}),
		(E.languages.tsconfig = E.languages.typoscript);
})(Prism);
Prism.languages.wasm = {
	comment: [/\(;[\s\S]*?;\)/, { pattern: /;;.*/, greedy: !0 }],
	string: { pattern: /"(?:\\[\s\S]|[^"\\])*"/, greedy: !0 },
	keyword: [
		{ pattern: /\b(?:align|offset)=/, inside: { operator: /=/ } },
		{
			pattern:
				/\b(?:(?:f32|f64|i32|i64)(?:\.(?:abs|add|and|ceil|clz|const|convert_[su]\/i(?:32|64)|copysign|ctz|demote\/f64|div(?:_[su])?|eqz?|extend_[su]\/i32|floor|ge(?:_[su])?|gt(?:_[su])?|le(?:_[su])?|load(?:(?:8|16|32)_[su])?|lt(?:_[su])?|max|min|mul|neg?|nearest|or|popcnt|promote\/f32|reinterpret\/[fi](?:32|64)|rem_[su]|rot[lr]|shl|shr_[su]|sqrt|store(?:8|16|32)?|sub|trunc(?:_[su]\/f(?:32|64))?|wrap\/i64|xor))?|memory\.(?:grow|size))\b/,
			inside: { punctuation: /\./ },
		},
		/\b(?:anyfunc|block|br(?:_if|_table)?|call(?:_indirect)?|data|drop|elem|else|end|export|func|get_(?:global|local)|global|if|import|local|loop|memory|module|mut|nop|offset|param|result|return|select|set_(?:global|local)|start|table|tee_local|then|type|unreachable)\b/,
	],
	variable: /\$[\w!#$%&'*+\-./:<=>?@\\^`|~]+/,
	number:
		/[+-]?\b(?:\d(?:_?\d)*(?:\.\d(?:_?\d)*)?(?:[eE][+-]?\d(?:_?\d)*)?|0x[\da-fA-F](?:_?[\da-fA-F])*(?:\.[\da-fA-F](?:_?[\da-fA-D])*)?(?:[pP][+-]?\d(?:_?\d)*)?)\b|\binf\b|\bnan(?::0x[\da-fA-F](?:_?[\da-fA-D])*)?\b/,
	punctuation: /[()]/,
};
// Extend Kipper with TypoScript
Prism.languages.kipper = Prism.languages.extend("typescript", {});
Prism.languages.kip = Prism.languages.kipper;
!(function () {
	if ("undefined" != typeof Prism && "undefined" != typeof document) {
		var e = "line-numbers",
			n = /\n(?!$)/g,
			t = (Prism.plugins.lineNumbers = {
				getLine: function (n, t) {
					if ("PRE" === n.tagName && n.classList.contains(e)) {
						var i = n.querySelector(".line-numbers-rows");
						if (i) {
							var r = parseInt(n.getAttribute("data-start"), 10) || 1,
								s = r + (i.children.length - 1);
							t < r && (t = r), t > s && (t = s);
							var l = t - r;
							return i.children[l];
						}
					}
				},
				resize: function (e) {
					r([e]);
				},
				assumeViewportIndependence: !0,
			}),
			i = void 0;
		window.addEventListener("resize", function () {
			(t.assumeViewportIndependence && i === window.innerWidth) ||
				((i = window.innerWidth), r(Array.prototype.slice.call(document.querySelectorAll("pre.line-numbers"))));
		}),
			Prism.hooks.add("complete", function (t) {
				if (t.code) {
					var i = t.element,
						s = i.parentNode;
					if (s && /pre/i.test(s.nodeName) && !i.querySelector(".line-numbers-rows") && Prism.util.isActive(i, e)) {
						i.classList.remove(e), s.classList.add(e);
						var l,
							o = t.code.match(n),
							a = o ? o.length + 1 : 1,
							u = new Array(a + 1).join("<span></span>");
						(l = document.createElement("span")).setAttribute("aria-hidden", "true"),
							(l.className = "line-numbers-rows"),
							(l.innerHTML = u),
							s.hasAttribute("data-start") &&
								(s.style.counterReset = "linenumber " + (parseInt(s.getAttribute("data-start"), 10) - 1)),
							t.element.appendChild(l),
							r([s]),
							Prism.hooks.run("line-numbers", t);
					}
				}
			}),
			Prism.hooks.add("line-numbers", function (e) {
				(e.plugins = e.plugins || {}), (e.plugins.lineNumbers = !0);
			});
	}
	function r(e) {
		if (
			0 !=
			(e = e.filter(function (e) {
				var n,
					t = ((n = e), n ? (window.getComputedStyle ? getComputedStyle(n) : n.currentStyle || null) : null)[
						"white-space"
					];
				return "pre-wrap" === t || "pre-line" === t;
			})).length
		) {
			var t = e
				.map(function (e) {
					var t = e.querySelector("code"),
						i = e.querySelector(".line-numbers-rows");
					if (t && i) {
						var r = e.querySelector(".line-numbers-sizer"),
							s = t.textContent.split(n);
						r || (((r = document.createElement("span")).className = "line-numbers-sizer"), t.appendChild(r)),
							(r.innerHTML = "0"),
							(r.style.display = "block");
						var l = r.getBoundingClientRect().height;
						return (
							(r.innerHTML = ""),
							{
								element: e,
								lines: s,
								lineHeights: [],
								oneLinerHeight: l,
								sizer: r,
							}
						);
					}
				})
				.filter(Boolean);
			t.forEach(function (e) {
				var n = e.sizer,
					t = e.lines,
					i = e.lineHeights,
					r = e.oneLinerHeight;
				(i[t.length - 1] = void 0),
					t.forEach(function (e, t) {
						if (e && e.length > 1) {
							var s = n.appendChild(document.createElement("span"));
							(s.style.display = "block"), (s.textContent = e);
						} else i[t] = r;
					});
			}),
				t.forEach(function (e) {
					for (var n = e.sizer, t = e.lineHeights, i = 0, r = 0; r < t.length; r++)
						void 0 === t[r] && (t[r] = n.children[i++].getBoundingClientRect().height);
				}),
				t.forEach(function (e) {
					var n = e.sizer,
						t = e.element.querySelector(".line-numbers-rows");
					(n.style.display = "none"),
						(n.innerHTML = ""),
						e.lineHeights.forEach(function (e, n) {
							t.children[n].style.height = e + "px";
						});
				});
		}
	}
})();
!(function () {
	if ("undefined" != typeof Prism && "undefined" != typeof document) {
		var e = [],
			t = {},
			n = function () {};
		Prism.plugins.toolbar = {};
		var a = (Prism.plugins.toolbar.registerButton = function (n, a) {
				var r;
				(r =
					"function" == typeof a
						? a
						: function (e) {
								var t;
								return (
									"function" == typeof a.onClick
										? (((t = document.createElement("button")).type = "button"),
										  t.addEventListener("click", function () {
												a.onClick.call(this, e);
										  }))
										: "string" == typeof a.url
										? ((t = document.createElement("a")).href = a.url)
										: (t = document.createElement("span")),
									a.className && t.classList.add(a.className),
									(t.textContent = a.text),
									t
								);
						  }),
					n in t ? console.warn('There is a button with the key "' + n + '" registered already.') : e.push((t[n] = r));
			}),
			r = (Prism.plugins.toolbar.hook = function (a) {
				var r = a.element.parentNode;
				if (r && /pre/i.test(r.nodeName) && !r.parentNode.classList.contains("code-toolbar")) {
					var o = document.createElement("div");
					o.classList.add("code-toolbar"), r.parentNode.insertBefore(o, r), o.appendChild(r);
					var i = document.createElement("div");
					i.classList.add("toolbar");
					var l = e,
						d = (function (e) {
							for (; e; ) {
								var t = e.getAttribute("data-toolbar-order");
								if (null != t) return (t = t.trim()).length ? t.split(/\s*,\s*/g) : [];
								e = e.parentElement;
							}
						})(a.element);
					d &&
						(l = d.map(function (e) {
							return t[e] || n;
						})),
						l.forEach(function (e) {
							var t = e(a);
							if (t) {
								var n = document.createElement("div");
								n.classList.add("toolbar-item"), n.appendChild(t), i.appendChild(n);
							}
						}),
						o.appendChild(i);
				}
			});
		a("label", function (e) {
			var t = e.element.parentNode;
			if (t && /pre/i.test(t.nodeName) && t.hasAttribute("data-label")) {
				var n,
					a,
					r = t.getAttribute("data-label");
				try {
					a = document.querySelector("template#" + r);
				} catch (e) {}
				return (
					a
						? (n = a.content)
						: (t.hasAttribute("data-url")
								? ((n = document.createElement("a")).href = t.getAttribute("data-url"))
								: (n = document.createElement("span")),
						  (n.textContent = r)),
					n
				);
			}
		}),
			Prism.hooks.add("complete", r);
	}
})();
!(function () {
	if ("undefined" != typeof Prism && "undefined" != typeof document)
		if (Prism.plugins.toolbar) {
			var e = {
				none: "Plain text",
				plain: "Plain text",
				plaintext: "Plain text",
				text: "Plain text",
				txt: "Plain text",
				html: "HTML",
				xml: "XML",
				svg: "SVG",
				mathml: "MathML",
				ssml: "SSML",
				rss: "RSS",
				css: "CSS",
				clike: "C-like",
				js: "JavaScript",
				abap: "ABAP",
				abnf: "ABNF",
				al: "AL",
				antlr4: "ANTLR4",
				g4: "ANTLR4",
				apacheconf: "Apache Configuration",
				apl: "APL",
				aql: "AQL",
				ino: "Arduino",
				arff: "ARFF",
				armasm: "ARM Assembly",
				"arm-asm": "ARM Assembly",
				art: "Arturo",
				asciidoc: "AsciiDoc",
				adoc: "AsciiDoc",
				aspnet: "ASP.NET (C#)",
				asm6502: "6502 Assembly",
				asmatmel: "Atmel AVR Assembly",
				autohotkey: "AutoHotkey",
				autoit: "AutoIt",
				avisynth: "AviSynth",
				avs: "AviSynth",
				"avro-idl": "Avro IDL",
				avdl: "Avro IDL",
				awk: "AWK",
				gawk: "GAWK",
				basic: "BASIC",
				bbcode: "BBcode",
				bnf: "BNF",
				rbnf: "RBNF",
				bsl: "BSL (1C:Enterprise)",
				oscript: "OneScript",
				csharp: "C#",
				cs: "C#",
				dotnet: "C#",
				cpp: "C++",
				cfscript: "CFScript",
				cfc: "CFScript",
				cil: "CIL",
				cmake: "CMake",
				cobol: "COBOL",
				coffee: "CoffeeScript",
				conc: "Concurnas",
				csp: "Content-Security-Policy",
				"css-extras": "CSS Extras",
				csv: "CSV",
				cue: "CUE",
				dataweave: "DataWeave",
				dax: "DAX",
				django: "Django/Jinja2",
				jinja2: "Django/Jinja2",
				"dns-zone-file": "DNS zone file",
				"dns-zone": "DNS zone file",
				dockerfile: "Docker",
				dot: "DOT (Graphviz)",
				gv: "DOT (Graphviz)",
				ebnf: "EBNF",
				editorconfig: "EditorConfig",
				ejs: "EJS",
				etlua: "Embedded Lua templating",
				erb: "ERB",
				"excel-formula": "Excel Formula",
				xlsx: "Excel Formula",
				xls: "Excel Formula",
				fsharp: "F#",
				"firestore-security-rules": "Firestore security rules",
				ftl: "FreeMarker Template Language",
				gml: "GameMaker Language",
				gamemakerlanguage: "GameMaker Language",
				gap: "GAP (CAS)",
				gcode: "G-code",
				gdscript: "GDScript",
				gedcom: "GEDCOM",
				gettext: "gettext",
				po: "gettext",
				glsl: "GLSL",
				gn: "GN",
				gni: "GN",
				"linker-script": "GNU Linker Script",
				ld: "GNU Linker Script",
				"go-module": "Go module",
				"go-mod": "Go module",
				graphql: "GraphQL",
				hbs: "Handlebars",
				hs: "Haskell",
				hcl: "HCL",
				hlsl: "HLSL",
				http: "HTTP",
				hpkp: "HTTP Public-Key-Pins",
				hsts: "HTTP Strict-Transport-Security",
				ichigojam: "IchigoJam",
				"icu-message-format": "ICU Message Format",
				idr: "Idris",
				ignore: ".ignore",
				gitignore: ".gitignore",
				hgignore: ".hgignore",
				npmignore: ".npmignore",
				inform7: "Inform 7",
				javadoc: "JavaDoc",
				javadoclike: "JavaDoc-like",
				javastacktrace: "Java stack trace",
				jq: "JQ",
				jsdoc: "JSDoc",
				"js-extras": "JS Extras",
				json: "JSON",
				webmanifest: "Web App Manifest",
				json5: "JSON5",
				jsonp: "JSONP",
				jsstacktrace: "JS stack trace",
				"js-templates": "JS Templates",
				keepalived: "Keepalived Configure",
				kts: "Kotlin Script",
				kt: "Kotlin",
				kumir: "KuMir (КуМир)",
				kum: "KuMir (КуМир)",
				latex: "LaTeX",
				tex: "TeX",
				context: "ConTeXt",
				lilypond: "LilyPond",
				ly: "LilyPond",
				emacs: "Lisp",
				elisp: "Lisp",
				"emacs-lisp": "Lisp",
				llvm: "LLVM IR",
				log: "Log file",
				lolcode: "LOLCODE",
				magma: "Magma (CAS)",
				md: "Markdown",
				"markup-templating": "Markup templating",
				matlab: "MATLAB",
				maxscript: "MAXScript",
				mel: "MEL",
				mongodb: "MongoDB",
				moon: "MoonScript",
				n1ql: "N1QL",
				n4js: "N4JS",
				n4jsd: "N4JS",
				"nand2tetris-hdl": "Nand To Tetris HDL",
				naniscript: "Naninovel Script",
				nani: "Naninovel Script",
				nasm: "NASM",
				neon: "NEON",
				nginx: "nginx",
				nsis: "NSIS",
				objectivec: "Objective-C",
				objc: "Objective-C",
				ocaml: "OCaml",
				opencl: "OpenCL",
				openqasm: "OpenQasm",
				qasm: "OpenQasm",
				parigp: "PARI/GP",
				objectpascal: "Object Pascal",
				psl: "PATROL Scripting Language",
				pcaxis: "PC-Axis",
				px: "PC-Axis",
				peoplecode: "PeopleCode",
				pcode: "PeopleCode",
				php: "PHP",
				phpdoc: "PHPDoc",
				"php-extras": "PHP Extras",
				"plant-uml": "PlantUML",
				plantuml: "PlantUML",
				plsql: "PL/SQL",
				powerquery: "PowerQuery",
				pq: "PowerQuery",
				mscript: "PowerQuery",
				powershell: "PowerShell",
				promql: "PromQL",
				properties: ".properties",
				protobuf: "Protocol Buffers",
				purebasic: "PureBasic",
				pbfasm: "PureBasic",
				purs: "PureScript",
				py: "Python",
				qsharp: "Q#",
				qs: "Q#",
				q: "Q (kdb+ database)",
				qml: "QML",
				rkt: "Racket",
				cshtml: "Razor C#",
				razor: "Razor C#",
				jsx: "React JSX",
				tsx: "React TSX",
				renpy: "Ren'py",
				rpy: "Ren'py",
				res: "ReScript",
				rest: "reST (reStructuredText)",
				robotframework: "Robot Framework",
				robot: "Robot Framework",
				rb: "Ruby",
				sas: "SAS",
				sass: "Sass (Sass)",
				scss: "Sass (Scss)",
				"shell-session": "Shell session",
				"sh-session": "Shell session",
				shellsession: "Shell session",
				sml: "SML",
				smlnj: "SML/NJ",
				solidity: "Solidity (Ethereum)",
				sol: "Solidity (Ethereum)",
				"solution-file": "Solution file",
				sln: "Solution file",
				soy: "Soy (Closure Template)",
				sparql: "SPARQL",
				rq: "SPARQL",
				"splunk-spl": "Splunk SPL",
				sqf: "SQF: Status Quo Function (Arma 3)",
				sql: "SQL",
				stata: "Stata Ado",
				iecst: "Structured Text (IEC 61131-3)",
				supercollider: "SuperCollider",
				sclang: "SuperCollider",
				systemd: "Systemd configuration file",
				"t4-templating": "T4 templating",
				"t4-cs": "T4 Text Templates (C#)",
				t4: "T4 Text Templates (C#)",
				"t4-vb": "T4 Text Templates (VB)",
				tap: "TAP",
				tt2: "Template Toolkit 2",
				toml: "TOML",
				trickle: "trickle",
				troy: "troy",
				trig: "TriG",
				ts: "TypeScript",
				tsconfig: "TSConfig",
				uscript: "UnrealScript",
				uc: "UnrealScript",
				uorazor: "UO Razor Script",
				uri: "URI",
				url: "URL",
				vbnet: "VB.Net",
				vhdl: "VHDL",
				vim: "vim",
				"visual-basic": "Visual Basic",
				vba: "VBA",
				vb: "Visual Basic",
				wasm: "WebAssembly",
				"web-idl": "Web IDL",
				webidl: "Web IDL",
				wiki: "Wiki markup",
				wolfram: "Wolfram language",
				nb: "Mathematica Notebook",
				wl: "Wolfram language",
				xeoracube: "XeoraCube",
				"xml-doc": "XML doc (.net)",
				xojo: "Xojo (REALbasic)",
				xquery: "XQuery",
				yaml: "YAML",
				yml: "YAML",
				yang: "YANG",
			};
			Prism.plugins.toolbar.registerButton("show-language", function (a) {
				var t = a.element.parentNode;
				if (t && /pre/i.test(t.nodeName)) {
					var o,
						s =
							t.getAttribute("data-language") ||
							e[a.language] ||
							((o = a.language) ? (o.substring(0, 1).toUpperCase() + o.substring(1)).replace(/s(?=cript)/, "S") : o);
					if (s) {
						var r = document.createElement("span");
						return (r.textContent = s), r;
					}
				}
			});
		} else console.warn("Show Languages plugin loaded before Toolbar plugin.");
})();
"undefined" != typeof Prism &&
	Prism.hooks.add("wrap", function (e) {
		"keyword" === e.type && e.classes.push("keyword-" + e.content);
	});
!(function () {
	function t(t) {
		var e = document.createElement("textarea");
		(e.value = t.getText()),
			(e.style.top = "0"),
			(e.style.left = "0"),
			(e.style.position = "fixed"),
			document.body.appendChild(e),
			e.focus(),
			e.select();
		try {
			var o = document.execCommand("copy");
			setTimeout(function () {
				o ? t.success() : t.error();
			}, 1);
		} catch (e) {
			setTimeout(function () {
				t.error(e);
			}, 1);
		}
		document.body.removeChild(e);
	}
	"undefined" != typeof Prism &&
		"undefined" != typeof document &&
		(Prism.plugins.toolbar
			? Prism.plugins.toolbar.registerButton("copy-to-clipboard", function (e) {
					var o = e.element,
						n = (function (t) {
							var e = {
								copy: "Copy",
								"copy-error": "Press Ctrl+C to copy",
								"copy-success": "Copied!",
								"copy-timeout": 5e3,
							};
							for (var o in e) {
								for (var n = "data-prismjs-" + o, c = t; c && !c.hasAttribute(n); ) c = c.parentElement;
								c && (e[o] = c.getAttribute(n));
							}
							return e;
						})(o),
						c = document.createElement("button");
					(c.className = "copy-to-clipboard-button"), c.setAttribute("type", "button");
					var r = document.createElement("span");
					return (
						c.appendChild(r),
						u("copy"),
						(function (e, o) {
							e.addEventListener("click", function () {
								!(function (e) {
									navigator.clipboard
										? navigator.clipboard.writeText(e.getText()).then(e.success, function () {
												t(e);
										  })
										: t(e);
								})(o);
							});
						})(c, {
							getText: function () {
								return o.textContent;
							},
							success: function () {
								u("copy-success"), i();
							},
							error: function () {
								u("copy-error"),
									setTimeout(function () {
										!(function (t) {
											window.getSelection().selectAllChildren(t);
										})(o);
									}, 1),
									i();
							},
						}),
						c
					);
					function i() {
						setTimeout(function () {
							u("copy");
						}, n["copy-timeout"]);
					}
					function u(t) {
						(r.textContent = n[t]), c.setAttribute("data-copy-state", t);
					}
			  })
			: console.warn("Copy to Clipboard plugin loaded before Toolbar plugin."));
})();
"undefined" != typeof Prism &&
	"undefined" != typeof document &&
	document.querySelector &&
	Prism.plugins.toolbar.registerButton("download-file", function (t) {
		var e = t.element.parentNode;
		if (e && /pre/i.test(e.nodeName) && e.hasAttribute("data-src") && e.hasAttribute("data-download-link")) {
			var n = e.getAttribute("data-src"),
				a = document.createElement("a");
			return (
				(a.textContent = e.getAttribute("data-download-link-label") || "Download"),
				a.setAttribute("download", ""),
				(a.href = n),
				a
			);
		}
	});
// Kipper extension
"undefined" != typeof Prism &&
	"undefined" != typeof document &&
	(() => {
		Prism.languages["kipper"] = {
			...Prism.languages["typescript"],
		};
	})();
